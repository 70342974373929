import React from "react";
import {
  FaEnvelope,
  FaFacebookF,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      {/* <!-- Footer Start --> */}
      <div
        class="container-fluid  text-light footer mt-5 py-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div class="container py-3">
          <div class=" container  row ">
            <div class="col-12 text-center">
              <h4 class="text-white mb-2">Our Office</h4>
            </div>
          </div>

          <div class=" container  row justify-content-center pt-4 ">
            <div className="col-xl-3 col-md-4  text-center">
              <p class="mb-2">
                <i class="fa fa-map-marker-alt me-3">
                  <FaMapMarkerAlt />
                </i>
                Ahmedabad, Gujarat, India
              </p>
            </div>
            <div className="col-xl-3 col-md-4 mt-md-0 mt-2 text-center">
              <p class="mb-2">
                <i class="fa fa-phone-alt me-3">
                  <FaPhoneAlt />
                </i>
                +012 345 67890
              </p>
            </div>
            <div className="col-xl-3 col-md-4 mt-md-0 mt-2 text-center">
              <p class="mb-2">
                <i class="fa fa-envelope me-3">
                  <FaEnvelope />
                </i>
                itechexs@gmail.com
              </p>
            </div>
           </div>

            <div class="container row pt-4">
              <div class="d-flex justify-content-center pt-2 text-center">
                <Link
                  class="btn btn-square btn-outline-light rounded-circle me-2"
                  href=""
                >
                  <i class="fab fa-twitter">
                    <FaTwitter />
                  </i>
                </Link>
                <Link
                  class="btn btn-square btn-outline-light rounded-circle me-2"
                  href=""
                >
                  <i class="fab fa-facebook-f">
                    <FaFacebookF />
                  </i>
                </Link>
                <Link
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                  href=""
                >
                  <i class="fab fa-youtube">
                    <FaYoutube />
                  </i>
                </Link>
                <Link
                  className="btn btn-square btn-outline-light rounded-circle me-2"
                  href=""
                >
                  <i class="fab fa-linkedin-in">
                    <FaLinkedinIn />
                  </i>
                </Link>
              </div>
            </div>
        </div>
      </div>
      {/* <!-- Footer End --> */}

      {/* <!-- Copyright Start --> */}
      <div class="container-fluid copyright py-4">
        <div class="container justify-content-center">
          <div class="row ">
            <div class="col  text-center mb-3 mb-md-0">
              &copy;{" "}
              <a class="border-bottom" href="#">
                ItechExs
              </a>
              , All Right Reserved.
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Copyright End --> */}
    </>
  );
}

export default Footer;
